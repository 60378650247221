import React, { useState, useEffect, useRef } from 'react';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

export default function ImageCarousel({ eventType, events }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const nextImage = () => {
    setCurrentIndex(currentIndex === events.length - 1 ? 0 : currentIndex + 1);
    console.log(currentIndex === events.length - 1 ? 0 : currentIndex + 1);
  }

  const previousImage = () => {
    setCurrentIndex(currentIndex === 0 ? events.length - 1 : currentIndex - 1);
    console.log(currentIndex === 0 ? events.length - 1 : currentIndex + 1);
  }

  if (!Array.isArray(events) || events.length <= 0) {
    return null;
  }

  useEffect(() => {
    intervalRef.current = setTimeout(() => {
      setCurrentIndex(currentIndex === events.length - 1 ? 0 : currentIndex + 1);
    }, 5000);

    return () => clearTimeout(intervalRef.current);
  }, [currentIndex])

  return (
    <div className="event-container">
      <div className="image-carousel-container">
        {
          events.length > 1 &&
            <MdKeyboardDoubleArrowLeft onClick={previousImage} className={"image-carousel-arrow"} />
        }
        <div className="event-poster-container">
          <img
            id={`${eventType}-event-poster-${currentIndex}`}
            className={"image-carousel-poster"}
            src={events.at(currentIndex).poster}
            alt={"Event Poster"}
          />
        </div>
        {
          events.length > 1 &&
            <MdKeyboardDoubleArrowRight onClick={nextImage} className={"image-carousel-arrow"} />
        }
      </div>
      <div className="event-line">
        <p>
          {currentIndex + 1}.
        </p>
        <p>
          {events.at(currentIndex).date}
        </p>
        <p>
          {events.at(currentIndex).location}
        </p>
      </div>
    </div>
  )
};