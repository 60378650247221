import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useGlobalState } from '../providers/globalState';

export default function Contact() {
  const form = useRef();
  const nameInput = useRef();
  const emailInput = useRef();
  const emailRegEx = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+$/;
  const messageInput = useRef();
  const sendButton = useRef();

  const {setEmailStatusModalOpen, setEmailStatusModalMessage} = useGlobalState();
  const [formIsValid, setFormIsValid] = useState(false);

  const onFormChanged = () => {
    if (nameInput.current.value !== '' && emailInput.current.value.match(emailRegEx) && messageInput.current.value !== '') {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (formIsValid) {
      emailjs.sendForm('service_m6bgp37', 'template_kvl3119', form.current, 'n9PMiUBvQOpvFP7qk').then((result) => {
        nameInput.current.value = '';
        emailInput.current.value = '';
        messageInput.current.value = '';
        sendButton.current.blur();
        setFormIsValid(false);
        setEmailStatusModalOpen(true);
        setEmailStatusModalMessage('The message was sent successfully!');
      }, (error) => {
        nameInput.current.value = '';
        emailInput.current.value = '';
        messageInput.current.value = '';
        setFormIsValid(false);
        setEmailStatusModalOpen(true);
        setEmailStatusModalMessage('The message was sent successfully!');
      });
    }
  };

  return (
    <>
      <form
        ref={form}
        onSubmit={sendEmail}
        onChange={onFormChanged}
        id='contact-form'
      >
        <label>
          Name
        </label>
        <input
          ref={nameInput}
          type='text'
          name='user_name'
        />
        <label>
          Email
        </label>
        <input
          ref={emailInput}
          type='email'
          name='user_email'
        />
        <label>
          Message
        </label>
        <textarea
          ref={messageInput}
          name="message"
        />
        {
          !formIsValid &&
            <p>
              The fields are not filled properly
            </p>
        }
        <input
          ref={sendButton}
          type='submit'
          value='Send'
          disabled={!formIsValid}
          className={formIsValid ? '' : 'input-disabled'}
        />
      </form>
    </>
  );
};