import React from 'react';
import { useGlobalState } from '../providers/globalState';

export default function Modal({ message }) {
  const {setEmailStatusModalOpen} = useGlobalState();

  return (
    <div
      className='notification-modal-background'
    >
      <div className='notification-modal'>
          <img
            className='notification-modal-close-button'
            src={require('../resources/CloseButton.webp')}
            alt='X'
            onClick={() => {
              setEmailStatusModalOpen(false);
            }}
          />
          <p>
            {message}
          </p>
      </div>
    </div>
  );
};