import React, {
  createContext,
  useState,
  useContext
} from 'react'

const GlobalState = createContext({});

export default function GlobalStateProvider({ children }) {
  const [emailStatusModalOpen, setEmailStatusModalOpen] = useState(false);
  const [emailStatusModalMessage, setEmailStatusModalMessage] = useState('');

  return (
    <GlobalState.Provider
      value={{
        emailStatusModalOpen,
        emailStatusModalMessage,
        setEmailStatusModalOpen,
        setEmailStatusModalMessage
      }}
    >
      {children}
    </GlobalState.Provider>
  );
}

export const useGlobalState = () => useContext(GlobalState);